import {combineReducers} from 'redux';

import menuReducer from './menu';
import skillReducer from './skills';
import jobTemplateReducer from './jobTemplates';
import proposalReducer from "./proposal";
import authReducer from "./auth";
import jobReducer from './job';
import candidateReducer from './candidate';
import businessReducer from './business';
import mailsReducer from "./mails";
import tagReducer from './tags';
import jobScreeningReducer from "./jobScreening";
import syncHistoryReducer from './syncHistory';

const appReducer = combineReducers({
    customization: menuReducer,
    skills: skillReducer,
    jobTemplates: jobTemplateReducer,
    proposals: proposalReducer,
    auth: authReducer,
    jobs: jobReducer,
    candidates: candidateReducer,
    businesses: businessReducer,
    tags: tagReducer,
    mailsReducer,
    jobScreening:jobScreeningReducer,
    syncHistory: syncHistoryReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
        state = {}
    }
    return appReducer(state, action)
}

export default rootReducer
// export default reducer;
