import * as actionTypes from '../../actions/syncHistory/actionTypes';

export const initialState = {
  syncHistoryData: [],
  isLoading: true
};

const syncHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SYNC_HISTORY:
      return {
        ...state,
        isLoading: false,
        syncHistoryData: action.data
      };
    case actionTypes.SET_SYNC_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

export default syncHistoryReducer;
