import { requestWithToken } from '../apiConfigs/request';

export const fetchCandidateDetails = (candidateId) => {
  return requestWithToken({
    url: `/candidates/${candidateId}`,
    method: 'GET',
    withCredentials: true
  });
}

export const updateCandidate = (candidateId, data) => {
  return requestWithToken({
    url: `/candidates/${candidateId}`,
    method: 'PATCH',
    data,
  });
}

export const deleteCandidate = (candidateId) => {
  return requestWithToken({
    url: `/candidates/${candidateId}`,
    method: 'DELETE'
  });
}

export const fetchCandidateList = (query) => {
  return requestWithToken({
    url: `/candidates${query}`,
    method: 'GET'
  });
}

export const fetchCandidateRole = () => {
  return requestWithToken({
    url: `/candidates/roles`,
    method: 'GET'
  })
}

export const fetchCredentials = (candidateId) => {
  return requestWithToken({
    url: `/candidates/${candidateId}/credentials`,
    method: 'GET'
  });
}

export const fetchAIGeneratedSummary = (candidateId) => {
  return requestWithToken({
    url: `/llm`,
    method: 'POST',
    data: {
      type: 'candidate_summary',
      payload: {
        profileId: candidateId,
      },
      modelType: localStorage.getItem('aiModel') ? localStorage.getItem('aiModel') : 'GPT',
      llmModel: localStorage.getItem('llmModel') ? localStorage.getItem('llmModel') : 'gpt-3.5-turbo',
    },
  });
};

export const parseCandidateCV = (data) => {
  return requestWithToken({
      url: `/cv/parse`,
      method: 'POST',
      data,
  });
}