import { lazy, useEffect } from 'react';

import Layout from '../molecules/layout';
import Loadable from '../elements/Loadable';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allowedRoutes } from '../molecules/layout/Sidebar/MenuList';
import { getUserInfo, getMetaDataInfo } from '../store/actions/auth';
import MailPage from '../pages/mails';


const UIPage = Loadable(lazy(() => import('../pages/UI')));
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard')));
const CandidateProfileEditPage = Loadable(lazy(() => import('../pages/candidate/candidateEditPage')));
const CandidatePDFPage = Loadable(lazy(() => import('../pages/candidate/candidatePDF')));
const CandidateProfileViewPage = Loadable(lazy(() => import('../pages/candidate/view')))
const CandidateListPage = Loadable(lazy(() => import('../pages/candidate/list')));
const CandidateCreatePage = Loadable(lazy(() => import('../pages/candidate/create')));
const CandidateReviewListPage = Loadable(lazy(() => import('../pages/candidateReview/list')));
const ScreeningCreatePage = Loadable(lazy(() => import('../pages/screening/create')));
const ScreeningListPage = Loadable(lazy(() => import('../pages/screening/list')));
const ScreeningDetailPage = Loadable(lazy(() => import('../pages/screening/detail')));
const ProposalPage = Loadable(lazy(() => import('../pages/proposal')));
const ProposalEdit = Loadable(lazy(() => import('../pages/proposal/ProposalEdit')));

const JobPage = Loadable(lazy(() => import('../pages/job')));
const JobCreatePage = Loadable(lazy(() => import('../pages/job/create')));
const JobEditPage = Loadable(lazy(() => import('../pages/job/edit')));
const JobDetailPage = Loadable(lazy(() => import('../pages/job/detail')));

const JobTemplatePage = Loadable(lazy(() => import('../pages/jobTemplate/list')));
const JobTemplateCreatePage = Loadable(lazy(() => import('../pages/jobTemplate/create')));
const JobTemplateEditPage = Loadable(lazy(() => import('../pages/jobTemplate/edit')));

const JobScreeningPage = Loadable(lazy(() => import('../pages/jobScreening')));
const JobScreeningCreatePage = Loadable(lazy(() => import('../pages/jobScreening/create')));
const JobScreeningEditPage = Loadable(lazy(() => import('../pages/jobScreening/edit')));
const JobApplicationPage = Loadable(lazy(() => import('../pages/jobApplication')));

const BusinessListPage = Loadable(lazy(() => import('../pages/businesses/list')));
const BusinessCreatePage = Loadable(lazy(() => import('../pages/businesses/create')));
const BusinessEditPage = Loadable(lazy(() => import('../pages/businesses/edit')));
const BusinessCardPage = Loadable(lazy(() => import('../pages/businesses/businessCardPage')));
const MyOpportunityPage = Loadable(lazy(() => import('../pages/opportunity/list')));
const MyOpportunityDetailPage = Loadable(lazy(() => import('../pages/opportunity/detail')));
const CandidateApplicationPage = Loadable(lazy(() => import('../pages/candidateApplication/list')));
const CandidateOpportunitySchedulePage = Loadable(lazy(() => import('../pages/candidateApplication/opportunitySchedule')));
const CandidatCalendarPage = Loadable(lazy(() => import('../pages/candidateApplication/calendar')));
const ResumeBuilderPage = Loadable(lazy(() => import('../pages/resumeBuilder')));
const TagsPage = Loadable(lazy(() => import('../pages/tags/list')));
const SyncHistoryPage = Loadable(lazy(() => import('../pages/syncHistory/list')));
const Login = Loadable(lazy(() => import("../pages/login")));
const PageNotFound = Loadable(lazy(() => import("../pages/ErrorPages/PageNotFound")));

const ProtectedRoute = ({children}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const {userType, userAuthenticated} = useSelector((state) => {
        return {
            userType: state?.auth?.user?.userType,
            userAuthenticated: state?.auth?.accessToken
        }
    });

    useEffect(() => {
        if (!userAuthenticated) {
            navigate('/login')
        } else if (!userType) {
            dispatch(getUserInfo())
            dispatch(getMetaDataInfo())
        }
        return () => {
        };
    }, [userAuthenticated, navigate])

    let isMatchPath = false;
    if (userType === "candidate" || userType === "business") {
        Object.keys(allowedRoutes?.[userType])?.forEach((key => {
            if (!isMatchPath) {
                isMatchPath = matchPath({path: key}, location.pathname)
            }
        }));
    }

    useEffect(() => {
        if (!isMatchPath && (userType === 'candidate' || userType === 'business')) {
            navigate("/job")
        }
    }, [userType, navigate])

    useEffect(() => {
        if(location.pathname === '/' &&  (userType === 'candidate' || userType === 'business')) {
            navigate("/job")
        } else if (location.pathname === '/') {
            navigate("/dashboard")
        }
    }, [location.pathname])

    if (isMatchPath || userType === "admin") {
        return (<>{children}</>)
    }
}

const MainRoutes = [
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: '/submit-interest',
        element: <Login/>
    },
    {
        path: '/employer-register',
        element: <Login/>
    },
    {
        path: '/',
        element: <ProtectedRoute><Layout/></ProtectedRoute>,
        children: [
            {
                path: 'default',
                element: <UIPage/>
            },
            {
                path: "dashboard",
                element: <DashboardPage/>
            },
            {
                path: '404',
                element: <PageNotFound/>,
            },
            {
                path: 'mailbox',
                element: <MailPage/>,
            },
            {
                path: 'settings',
                children: [
                    {
                        path: 'business/create',
                        element: (<ProtectedRoute><BusinessCreatePage /></ProtectedRoute>),
                    },
                    {
                        path: 'business/:businessId',
                        element: (<ProtectedRoute><BusinessEditPage /></ProtectedRoute>),
                    },
                ],
            },
            {
                path: 'candidate',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><CandidateListPage/></ProtectedRoute>),
                    },
                    {
                        path: ":candidateId",
                        element: (<ProtectedRoute><CandidateProfileViewPage/></ProtectedRoute>),
                    },
                    {
                        path: 'create',
                        element: (<ProtectedRoute><CandidateCreatePage/></ProtectedRoute>)
                    },
                    {
                        path: 'edit/:candidateId',
                        element: (<ProtectedRoute><CandidateProfileEditPage/></ProtectedRoute>)
                    },
                    {
                        path: "calendar",
                        element: (<ProtectedRoute><CandidatCalendarPage/></ProtectedRoute>),
                    },
                    {
                        path: "calendar/:opportunityUuid",
                        element: (<ProtectedRoute><CandidateOpportunitySchedulePage/></ProtectedRoute>),
                    },
                ]
            },
            {
                path: 'calendar',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><CandidatCalendarPage/></ProtectedRoute>),
                    },
                    {
                        path: ":opportunityUuid",
                        element: (<ProtectedRoute><CandidateOpportunitySchedulePage/></ProtectedRoute>),
                    },
                ]
            },
            {
                path: 'candidate-review',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><CandidateReviewListPage/></ProtectedRoute>),
                    },
                    {
                        path: 'edit/:candidateId',
                        element: (<ProtectedRoute><CandidateProfileEditPage/></ProtectedRoute>)
                    },
                    {
                        path: 'candidate-pdf/:candidateId',
                        element: (<ProtectedRoute><CandidatePDFPage/></ProtectedRoute>)
                    },
                ]
            },
            {
                path: 'companySetup',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><BusinessCardPage /></ProtectedRoute>),
                    },
                ],
            },
            {
                path: 'company',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><BusinessListPage/></ProtectedRoute>),
                    },
                    {
                        path: 'create',
                        element: (<ProtectedRoute><BusinessCreatePage/></ProtectedRoute>)
                    },
                    {
                        path: 'edit/:businessId',
                        element: (<ProtectedRoute><BusinessEditPage/></ProtectedRoute>)
                    },
                ]
            },
            {
                path: '/screening',
                element: (
                    <ProtectedRoute>
                        <ScreeningListPage/>
                    </ProtectedRoute>
                )
            },
            {
                path: '/screening/create',
                element: (
                    <ProtectedRoute>
                        <ScreeningCreatePage/>
                    </ProtectedRoute>
                )
            },
            {
                path: '/screening/edit/:screeningId',
                element: (
                    <ProtectedRoute>
                        <ScreeningCreatePage/>
                    </ProtectedRoute>
                )
            },
            {
                path: '/screening/:screeningId',
                element: (
                    <ProtectedRoute>
                        <ScreeningDetailPage/>
                    </ProtectedRoute>
                )
            },
            {
                path: 'proposal',
                children: [
                    {
                        index: true,
                        element: (
                            <ProtectedRoute>
                                <ProposalPage/>
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: 'create',
                        element: (
                            <ProtectedRoute>
                                <ProposalEdit/>
                            </ProtectedRoute>
                        )
                    },
                    {
                        path: ':proposalId',
                        element: (
                            <ProtectedRoute>
                                <ProposalEdit/>
                            </ProtectedRoute>
                        )
                    }
                ]
            },
            {
                path: 'job-screening',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><JobScreeningPage/></ProtectedRoute>),
                    },
                    {
                        path: 'create',
                        element: (<ProtectedRoute><JobScreeningCreatePage/></ProtectedRoute>),
                    },
                    {
                        path: 'edit/:jobId',
                        element: (<ProtectedRoute><JobScreeningEditPage/></ProtectedRoute>),
                    }
                ]
            },
            {
                path: 'job-application',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><JobApplicationPage/></ProtectedRoute>),
                    }
                  ]
            },
            {
                path: 'job',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><JobPage/></ProtectedRoute>),
                    },
                    {
                        path: 'create',
                        element: (<ProtectedRoute><JobCreatePage/></ProtectedRoute>),
                    },
                    {
                        path: 'edit/:jobId',
                        element: (<ProtectedRoute><JobEditPage/></ProtectedRoute>),
                    },
                    {
                        path: ':jobId',
                        element: (<ProtectedRoute><JobDetailPage/></ProtectedRoute>),
                    },
                    {
                        path: ':jobId/candidate-applications',
                        children: [
                            {
                                index: true,
                                element: (<ProtectedRoute><CandidateApplicationPage/></ProtectedRoute>),
                            },
                        ]
                    },
                ]
            },
            {
                path: 'job-template',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><JobTemplatePage/></ProtectedRoute>),
                    },
                    {
                        path: 'create',
                        element: (<ProtectedRoute><JobTemplateCreatePage/></ProtectedRoute>),
                    },
                    {
                        path: ':templateId',
                        element: (<ProtectedRoute><JobTemplateEditPage/></ProtectedRoute>),
                    },
                ]
            },
            {
                path: 'my-opportunity',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><MyOpportunityPage/></ProtectedRoute>),
                    },
                    {
                        path: ':jobId',
                        element: (<ProtectedRoute><MyOpportunityDetailPage/></ProtectedRoute>),
                    },
                ]
            },
            {
                path: '/resume-builder/:candidateId',
                element: (
                    <ProtectedRoute>
                        <ResumeBuilderPage/>
                    </ProtectedRoute>
                )
            },
            {
                path: 'tags',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><TagsPage/></ProtectedRoute>),
                    },
                ]
            },
            {
                path: 'sync-history',
                children: [
                    {
                        index: true,
                        element: (<ProtectedRoute><SyncHistoryPage/></ProtectedRoute>),
                    },
                ]
            },

        ]
    }
];

export default MainRoutes;
