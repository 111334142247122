import {
  IconDashboard,
  IconUser,
  IconUserSearch,
  IconReportSearch,
  IconBulb,
  IconClipboardData,
  IconBriefcase,
  IconTemplate,
  IconFileDollar,
  IconMail,
  IconTags
} from '@tabler/icons-react';

const icons = {
  IconDashboard,
  IconUser,
  IconUserSearch,
  IconReportSearch,
  IconBulb,
  IconClipboardData,
  IconBriefcase,
  IconTemplate,
  IconFileDollar,
  IconMail,
  IconTags
};

const dashboard = {
  id: 'dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'job-template',
      title: 'Job Templates',
      type: 'item',
      url: '/job-template',
      icon: icons.IconTemplate,
      breadcrumbs: false
    },
    {
      id: 'screening',
      title: 'Screening',
      type: 'item',
      url: '/screening',
      icon: icons.IconUserSearch,
      breadcrumbs: false
    },
    {
      id: 'candidate',
      title: 'Geeks',
      type: 'item',
      url: '/candidate',
      icon: icons.IconUser,
      breadcrumbs: false
    },
    {
      id: 'proposal',
      title: 'Proposal',
      type: 'item',
      url: '/proposal',
      icon: icons.IconFileDollar,
      breadcrumbs: false
    },
    {
      id: 'job-application',
      title: 'Jobs Application',
      type: 'item',
      url: '/job-application',
      icon: icons.IconReportSearch,
      breadcrumbs: false
    },
    {
      id: 'job',
      title: 'Jobs',
      type: 'item',
      url: '/job',
      icon: icons.IconReportSearch,
      breadcrumbs: false
    },
    {
      id: 'job-screening',
      title: 'Jobs Screening',
      type: 'item',
      url: '/job-screening',
      icon: icons.IconReportSearch,
      breadcrumbs: false
    },
    {
      id: 'candidate-review',
      title: 'Candidate Review',
      type: 'item',
      url: '/candidate-review',
      icon: icons.IconUser,
      breadcrumbs: false
    },
    {
      id: 'company',
      title: 'Companies',
      type: 'item',
      url: '/company',
      icon: icons.IconBriefcase,
    },
    {
      id: 'my-opportunity',
      title: 'My Opportunity',
      type: 'item',
      url: '/my-opportunity',
      icon: icons.IconBulb,
      breadcrumbs: false
    },
    {
      id: 'mailbox',
      title: 'Mailbox',
      type: 'item',
      url: '/mailbox',
      icon: icons.IconMail,
      breadcrumbs: false
    },
    {
      id: 'tags',
      title: 'Tags',
      type: 'item',
      url: '/tags',
      icon: icons.IconTags,
      breadcrumbs: false
    },
    {
      id: 'sync-history',
      title: 'Sync History',
      type: 'item',
      url: '/sync-history',
      icon: icons.IconTags,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
