import {requestWithToken} from "../apiConfigs/request";

export const createJob = (data) => {
  return requestWithToken({
    url: '/job',
    method: 'POST',
    data,
  });
}

export const fetchJobById = (jobId) => {
  return requestWithToken({
      url: `/job/${jobId}`,
      method: 'GET',
  });
}

export const fetchJobList = (query) => {
  return requestWithToken({
    url: `/job${query}`,
    method: 'GET'
  });
}

export const updateJob = (jobId, data) => {
  return requestWithToken({
    url: `/job/${jobId}`,
    method: 'PATCH',
    data,
  });
}

export const fetchJobCandidates = (jobId, query="") => {
  return requestWithToken({
      url: `/job/${jobId}/candidates${query}`,
      method: 'GET',
  });
}

export const closeJobById = (jobId) => {
  return requestWithToken({
      url: `/job/${jobId}/close`,
      method: 'PATCH',
  });
}

export const parseJob = (data) => {
  return requestWithToken({
    url: '/job-parsing/parse',
    method: 'POST',
    data,
  });
}

export const createJobApplication = (jobId, data) => {
  return requestWithToken({
    url: `/job/${jobId}/apply`,
    method: 'POST',
    data
  });
}

export const fetchJobProxyEmails = (jobId, candidateId) => {
  return requestWithToken({
      url: `/job/${jobId}/proxy-emails/${candidateId}`,
      method: 'GET',
  });
}


export const deleteJob = (jobId) => {
  return requestWithToken({
      url: `/job/${jobId}`,
      method: 'DELETE',
  });
}