import {
    createUserInterest,
    fetchUserInfo,
    fetchMetaDataInfo,
    loginCandidate,
    loginWithVerifyToke,
    logOutUserService,
    registerCandidate,
    registerEmployer,
} from '../../../services/auth.service';
import * as actionTypes from './actionTypes';
import { _setToken, setupRefreshTimer } from '../../../utils/authFunctions';

export const setAuthData = (data) => {
    return {
        type: actionTypes.SET_AUTH_DATA,
        data,
    };
};

export const signUpUser = (userData) => {
    return dispatch => {
        return registerCandidate(userData).then(res => {
            localStorage.setItem('accessToken', res?.data.tokens.access.token);
            localStorage.setItem('refreshToken', res?.data.tokens.refresh.token);
            dispatch(setAuthData(res?.data));
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const signInUser = (userData) => {
    return dispatch => {
        return loginCandidate(userData).then(res => {
            _setToken(res?.data?.tokens);
            setupRefreshTimer();
            dispatch(setAuthData(res?.data));
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const getUserInfo = () => {
    return dispatch => {
        return fetchUserInfo().then(res => {
            dispatch({ type: actionTypes.SET_USER_INFO, data: res?.data });
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const getMetaDataInfo = () => {
    return dispatch => {
        return fetchMetaDataInfo().then(res => {
            dispatch({ type: actionTypes.SET_META_DATA_INFO, data: res?.data });
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const logOutUser = () => {
    return dispatch => {
        return logOutUserService().then(res => {
            localStorage.clear();
            dispatch({ type: 'CLEAR_STORE' });
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const submitUserInterest = (userData) => {
    return dispatch => {
        return createUserInterest(userData).then(res => {
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const signInWithVerifyToken = (token) => {
    return dispatch => {
        return loginWithVerifyToke(token).then(res => {
            _setToken(res?.data?.tokens);
            setupRefreshTimer();
            dispatch(setAuthData(res?.data));
            return res;
        }).catch(err => {
            console.log(err);
            return err;
        });
    };
};

export const signUpEmployer = (data) => {
    return dispatch => {
        return registerEmployer(data).then((res) => {
            return res;
        }).catch((error) => {
            console.log(error);
            return error;
        });
    };
};
