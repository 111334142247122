import * as actionTypes from './actionTypes';
import { fetchSyncHistoryList } from '../../../services/syncHistory.service';

export const setSyncHistoryData = (data) => {
  return {
    type: actionTypes.SET_SYNC_HISTORY,
    data,
  }
}

export const getSyncHistory = (params="") => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_SYNC_HISTORY_LOADING })
    return fetchSyncHistoryList(params).then(res => {
      dispatch(setSyncHistoryData(res?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
