import React, { useEffect, useState }  from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { CreateButton } from '../../elements/CreateButton';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';


const LLM_MAPPINGS = {
  GPT_3_5_TRUBO: 'GPT 3.5 Turbo',
  GPT_4_TURBO: 'GPT 4 Turbo',
  GPT_4O: 'GPT 4o',
  CLAUDE_3_HAIKU: 'Claude 3 Haiku',
  CLAUDE_3_OPUS: 'Claude 3 Opus',
  CLAUDE_3_SONNET: 'Claude 3 Sonnet'
}

export default function LLMModal(props) {

  const [llmModel, setLLMModel] = useState(localStorage.getItem('llmModel')
    ? localStorage.getItem('llmModel')
    : props?.aiModel === 'GPT' ? 'gpt-3.5-turbo' : 'claude-3-opus-20240229');

  const aiLLMs = props?.llmModels?.[props?.aiModel] || {};
  const LLMs = Object.keys(aiLLMs)?.map((llm) => {
    return {
      'label': LLM_MAPPINGS[llm],
      'value': aiLLMs[llm]
    }
  });

  return (
    <div>
      <Dialog open={props?.isOpen} onClose={props?.handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{props?.aiModel} LLM Models</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '16px' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby='llm-radio-buttons-group-label'
                value={llmModel}
                name="llm-radio-buttons-group"
                onChange={(event) => {
                  setLLMModel(event?.target?.value)
                  props?.setSelectedLLMModel(event?.target?.value);
                  localStorage.setItem('llmModel', event?.target?.value)
                  props?.handleClose();
                }}
              >
                {
                  LLMs?.map(item => {
                    return (
                      <FormControlLabel value={item?.value} control={<Radio />} label={item?.label} />
                    )
                  })
                }
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
