// material-ui
import { useState } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from '../MenuItems';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import LLMModal from '../../../Modals/llmModal';


export const allowedRoutes = {
  candidate: {
    '/job': true,
    '/job/:jobId': true,
    '/my-opportunity': true,
    '/my-opportunity/:jobId': true,
  },
  business: {
    '/companySetup':true,
    '/job': true,
    '/job/:jobId': true,
    'job/edit/:jobId':true,
    '/settings/business/create': true,
    '/settings/business/:businessId': true
  },
};

const LLM_MODEL_MAPPINGS = {
  "gpt-3.5-turbo": 'GPT 3.5 Turbo',
  "gpt-4-0125-preview": 'GPT 4 Turbo',
  "gpt-4o": 'GPT 4o',
  "claude-3-haiku-20240307": 'Claude 3 Haiku',
  "claude-3-opus-20240229": 'Claude 3 Opus',
  "claude-3-sonnet-20240229": 'Claude 3 Sonnet'
}

const MenuList = () => {
    const [aiModel, setAiModel] = useState(localStorage.getItem('aiModel') ? localStorage.getItem('aiModel') : 'GPT');
    const [selectedLLMModel, setSelectedLLMModel] = useState(localStorage.getItem('llmModel')
    ? localStorage.getItem('llmModel')
    : aiModel === 'GPT' ? 'gpt-3.5-turbo' : 'claude-3-opus-20240229');
    const [openLLMModal, setOpenLLMModal] = useState(false);
    const {userType, metaData} = useSelector((state) => {
      return {
        userType: state?.auth?.user?.userType,
        metaData: state?.auth?.metaData
      }
    });

    const navItems = menuItem.items.map((item) => {
        let updatedItem = _.cloneDeep(item)
        updatedItem.children  = updatedItem.children.filter(({url}) => {
            return allowedRoutes?.[userType]?.[url] || userType === "admin";
        });
        switch (updatedItem.type) {
            case 'group':
                return <NavGroup key={updatedItem.id} item={updatedItem} />;
            default:
                return (
                    <Typography key={updatedItem.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>
        {navItems}
      {userType === 'admin' ?
        <div className="flex justify-start">
          <Box mt={2} ml={4}>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>Select AI Model</FormLabel>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                value={aiModel}
                name="radio-buttons-group"
                onChange={(event) => {
                  setAiModel(event?.target?.value)
                  localStorage.setItem('aiModel', event?.target?.value)
                }}
                onClick={() => {
                  setOpenLLMModal(true);
                }}
              >
                <FormControlLabel value='GPT' control={<Radio />} label={`GPT ${aiModel === 'GPT' && selectedLLMModel ? `(${LLM_MODEL_MAPPINGS[selectedLLMModel]})` : ''}`} />
                <FormControlLabel value="CLAUDE" control={<Radio />} label={`Claude ${aiModel === 'CLAUDE' && selectedLLMModel ? `(${LLM_MODEL_MAPPINGS[selectedLLMModel]})` : ''}`} />
              </RadioGroup>
            </FormControl>
          </Box>
          <LLMModal
            key={aiModel}
            isOpen={openLLMModal}
            handleClose={() => {
              setOpenLLMModal(false);
            }}
            aiModel={aiModel}
            llmModels={metaData?.llmModels}
            setSelectedLLMModel={setSelectedLLMModel}
          />
        </div>
        :
        null
      }
    </>;
};

export default MenuList;
